import {
  createHttpClientPollingInstance,
  createInstance,
  createInstanceWithHeaders
} from './createInstance'

// PMM (Product Model Management)
export const pmmHttpClient = createInstance({
  url: process.env.VUE_APP_PMM_URL
})

// PMV (Product Model Validation)
export const pmvHttpClient = createInstance({
  url: process.env.VUE_APP_PMV_URL
})

export const pmvHttpClientWithHeaders = createInstanceWithHeaders({
  url: process.env.VUE_APP_PMV_URL
})

// VM (Variant Management)
export const vmHttpClient = createInstance({
  url: process.env.VUE_APP_VM_URL
})

// UM (User Management)
export const umHttpClient = createInstance({
  url: process.env.VUE_APP_UM_URL
})

// PAC (Portfolio Assistant Configuration)
export const pacHttpClient = createInstance({
  url: process.env.VUE_APP_PAC_URL
})

// Feedback (Feedback endpoints)
export const feedbackHttpClient = createInstance({
  url: process.env.VUE_APP_FEEDBACK_URL
})

export const whatsNewHttpClient = createInstance({
  url: process.env.VUE_APP_WHATS_NEW_URL
})

export const notificationsHttpClient = createInstance({
  url: process.env.VUE_APP_NOTIFICATIONS_URL
})

// PAC (Portfolio Assistant Configuration) specific for polling use cases
export const pacHttpClientPolling = createHttpClientPollingInstance(
  { url: process.env.VUE_APP_PAC_URL, ignoreStatuses: [404] }
)
