import { pmvHttpClient, pmvHttpClientWithHeaders } from '@/api/instances'
import {
  BOM_VALIDATION,
  BOM_VALIDATION_EXPORT,
  BOM_VALIDATION_TRIGGER,
  BOM_VALIDATIONS,
  INVALID_CHOICES_VALIDATION_EXPORT,
  UNUSED_KV_EXPLANATION,
  BOM_VALIDATION_EXPLANATION
} from './endpoints'

// DELETE: BOM Validation
export const deleteBOMValidation = async (validationId = '') => pmvHttpClient({
  url: BOM_VALIDATION.replace(':validationId', validationId),
  method: 'DELETE'
})

// GET: Export Excel BOM
export const exportBOMExcel = async (validationId = '') => pmvHttpClientWithHeaders({
  url: BOM_VALIDATION_EXPORT.replace(':validationId', validationId),
  method: 'GET',
  responseType: 'blob'
})

// GET: Export Excel Invalid Choices
export const exportInvalidChoicesExcel = async (validationId = '') => pmvHttpClient({
  url: INVALID_CHOICES_VALIDATION_EXPORT.replace(':validationId', validationId),
  method: 'GET',
  responseType: 'blob'
})

// GET: Unused KV Explanation
export const getUnusedKVExplanation = async ({
  pmEncodedBusinessName = '',
  componentId = '',
  unusedKVId = '',
  initialPP = '',
  finalPP = ''
} = {}) => {
  const mapObj = {
    ':pmEncodedBusinessName': pmEncodedBusinessName,
    ':componentId': componentId,
    ':unusedKVId': unusedKVId
  }

  return pmvHttpClient({
    url: UNUSED_KV_EXPLANATION.replace(
      /:pmEncodedBusinessName|:componentId|:unusedKVId/gi,
      matched => mapObj[matched]
    ),
    method: 'GET',
    params: {
      initialPP,
      finalPP
    }
  })
}

// GET: Perform BOM Validation
export const triggerBOMValidation = async ({
  encodedBusinessName = '',
  componentList = [],
  initialPP = '',
  finalPP = '',
  isLEAndTValidationEnabled = false
} = {}) => pmvHttpClient({
  url: BOM_VALIDATION_TRIGGER.replace(':encodedBusinessName', encodedBusinessName),
  method: 'POST',
  data: {
    componentList,
    initialPP,
    finalPP,
    isLEAndTValidationEnabled
  }
})

// GET: BOM Validations
export const getBOMValidations = async () => pmvHttpClient({
  url: BOM_VALIDATIONS,
  method: 'GET'
})

// GET: BOM Validation
export const getBOMValidation = async (validationId = '') => pmvHttpClient({
  url: BOM_VALIDATION.replace(':validationId', validationId),
  method: 'GET'
})

// POST: Create correlationId for BOM validation explanation
export const requestBOMValidationExplanation = async ({
  componentValidationID = '',
  componentID = '',
  componentVariantID = ''
} = {}) => pmvHttpClient({
  url: BOM_VALIDATION_EXPLANATION,
  method: 'POST',
  data: {
    componentValidationID,
    componentID,
    componentVariantID
  }
})

// GET: BOM validations explanation
export const getBOMValidationExplanation = async (correlationId = '') => pmvHttpClient({
  url: `${ BOM_VALIDATION_EXPLANATION }/${ correlationId }`,
  method: 'GET'
})
